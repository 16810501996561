import React, { useState, useEffect } from 'react';
import {
  Navbar,
  Nav
} from 'react-bootstrap';
import { useStaticQuery, graphql} from "gatsby"

const  Navigation = ({ brandLogo, title }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isTransparent, setIsTransparent] = useState("transparent");

  const listenScrollEvent = (e) => {
    if (window.scrollY > 100) {
      setIsTransparent("dark")
    } else {
      setIsTransparent("transparent")
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  })

  const toggle = () => setIsOpen(!isOpen);

  const data = useStaticQuery(graphql`
    query SiteLinksQuery {
      site {
        siteMetadata {
          links {
            href
            text
          }
        }
      }
    }
  `)

  const links=data.site.siteMetadata.links

  return (
    <Navbar variant="dark" bg={isTransparent} expand="md" fixed="top">
      <Navbar.Brand href="/"><img src={brandLogo} alt="leonel-logo" style={{ height: "25px" }}></img></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {links.map( (link,index) => (
              <Nav.Link key={index} onClick={isOpen && toggle} href={link.href}>{link.text}</Nav.Link>
          )
            )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;