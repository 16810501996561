import React from "react"
import Parallax from 'react-rellax'
import { Container, Row, Col } from 'react-bootstrap'

import Background from '../../images/bg4.jpg'

import Logo from '../../images/logoNew.png'

import style from "./Top.module.css"

const Top = () => (
  <Parallax speed={-5}>
    <section className={style.topSection}>
      <div className={style.imgContainer}>
        <img src={Background} alt="main-background" />
      </div>
      <div className={style.content}>
        <Container>
          <Row>
            <Col md="6" className="mr-auto ml-auto">
              <img className={`${style.cropped}`} src={Logo} alt="main-logo-leonelduarte" />
            </Col>
          </Row>
        </Container>
        
      </div>
    </section>
  </Parallax>
)

export default Top
