import PropTypes from "prop-types"
import React from "react"

import Navigation from '../Navigation/Navigation'

import Logo from '../../images/leonel-icon.png'

const Header = ({ siteTitle }) => (
  <header>
    <Navigation brandLogo={Logo} title={siteTitle} />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
